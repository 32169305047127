'use client';

import { NextIntlClientProvider } from 'next-intl';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { defaultLocale, isValidLocale, Locales, Messages, importLocaleMessages } from '@deltasierra/translations/core';

const getLocale = (): Locales => {
    const localeCookie: string | undefined = document.cookie
        .split('; ')
        .find((cookie: string) => cookie.startsWith('i18next='))
        ?.split('=')[1];
    return isValidLocale(localeCookie) ? localeCookie : defaultLocale;
};

function useLocale(): Locales | null {
    const [locale, setLocale] = useState<Locales | null>(null);

    useEffect(() => {
        setLocale(getLocale());
    }, []);
    return locale;
}

export function NextIntlProvider({ children }: { children: ReactNode }): JSX.Element | null {
    const [messages, setMessages] = useState<Messages | undefined>();

    const locale = useLocale();

    const getMessages = useCallback(async (localeToGet: Locales) => {
        const newMessages: Messages = await importLocaleMessages(localeToGet);
        return setMessages(newMessages);
    }, []);

    useEffect(() => {
        if (!locale) {
            return;
        }
        void getMessages(locale);
    }, [getMessages, locale]);

    if (!locale || !messages) {
        return null;
    }

    return (
        <NextIntlClientProvider locale={locale} messages={messages}>
            {children}
        </NextIntlClientProvider>
    );
}

NextIntlProvider.displayName = 'NextIntlProvider';
