import { theme } from './theme';

export const global = {
    '@media print': {
        body: {
            backgroundColor: 'white',
        },
    },
    body: {
        '&::backdrop': {
            backgroundColor: theme.palette.grey['50'],
        },
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.grey['900'],
        fontWeight: 400,
        margin: 0,
        /* Reset safe area padding for ios */
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
        paddingTop: 'env(safe-area-inset-top)',
    },
    html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        boxSizing: 'border-box',
    },
};
