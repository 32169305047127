'use client';

import { ReactNode } from 'react';
import { ThemeRegistry } from '@deltasierra/react/theme';
import { NextIntlProvider } from '@deltasierra/translations/react';

type AllPageRequiredProvidersProps = {
    children: ReactNode;
};

/**
 * These providers are required on every page including the global error page.
 * This is limited to the absolute minimum because if any of these fail, the whole page can fail.
 *
 * Ideally we would create an error page that doesn't need both of these to prevent separating these
 * out however we currently do not have a need.
 */
export function AllPageRequiredProviders({ children }: AllPageRequiredProvidersProps): JSX.Element {
    return (
        <ThemeRegistry>
            <NextIntlProvider>{children}</NextIntlProvider>
        </ThemeRegistry>
    );
}
AllPageRequiredProviders.displayName = 'AllPageRequiredProviders';
