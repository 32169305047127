'use client';

import { ErrorPageContainerSafe } from '../components/ErrorPageContainerSafe';
import { AllPageRequiredProviders } from '../components/core/AllPageRequiredProviders';

/**
 * This is the global error fallback page. This component completely overwrites the root layout when
 * an error isn't successfully handled down the chain. Most likely this should only occur if
 * a necessary provider's provided in the root layout is broken. We should avoid showing this global
 * error page at all costs, but it is here as a final fallback.
 *
 * Any providers that are not required should not be included here as they could be the cause of problems
 * when rendering the page and this is the final catch-all.
 *
 * This is the fallback logic:
 * (If the code throws an exception in $A then it will fallback to $B)
 * - `src/layout.tsx` -> `src/global-error.tsx`
 * - `src/error.tsx` -> `src/global-error.tsx`
 * - `src/page.tsx` -> `src/error.tsx`
 * - `src/(app)/layout.tsx` -> `src/error.tsx`
 * - `src/(app)/some-page.tsx` -> `src/(app)/error.tsx` if it exists, otherwise -> `src/error.tsx`
 * - `src/(app)/nested/page.tsx` -> `src/(app)/nested/error.tsx` if it exists, otherwise -> `src/(app)/error.tsx`
 */
export default function GlobalError({
    error,
    reset,
}: {
    error: unknown;
    reset: () => void;
}): JSX.Element {
    return (
        <html>
            <body>
                {/* The `<AllPageRequiredProviders/>` component must be rendered after the body
                 * because it delays rendering of its children
                 */}
                <AllPageRequiredProviders>
                    {/*
                     * We render the ErrorPage without the `appWebRouter` here because this is the
                     * final fallback and the router is only available if we have the AppConfig context
                     * loaded (which we do not).
                     */}
                    <ErrorPageContainerSafe
                        appWebRouter={null}
                        error={error}
                        reset={reset}
                    />
                </AllPageRequiredProviders>
            </body>
        </html>
    );
}
GlobalError.displayName = 'GlobalError';
