'use client';

import { ReactNode } from 'react';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { global } from './global';
import { theme } from './theme';

export const ThemeRegistry = ({ children }: { children: ReactNode }): JSX.Element => (
    <AppRouterCacheProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={global} />
                {children}
            </ThemeProvider>
        </LocalizationProvider>
    </AppRouterCacheProvider>
);

ThemeRegistry.displayName = 'ThemeRegistry';
